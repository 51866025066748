import api from "./api";
import authHeader from "@/helpers/auth-header";

const moduleName = "explanation";

export default {
  async find(runningSubtestId, questionId) {
    const explanation = await api().get(
      `${moduleName}/running_subtest/${runningSubtestId}/question/${questionId}/find`,
      {
        headers: authHeader()
      }
    );

    return explanation.data.data;
  }
};

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"4","order-md":"last"}},[_c('v-sheet',{staticClass:"pa-4",attrs:{"min-height":"100","rounded":"lg","outlined":""}},[_c('v-row',_vm._l((_vm.questions),function(question){return _c('v-col',{key:question.position,staticClass:"text-center",attrs:{"md":"3"}},[_c('v-btn',{attrs:{"color":question.position === _vm.questionNumber
                  ? 'success'
                  : question.isAnswered
                  ? 'primary'
                  : 'default'},on:{"click":function($event){return _vm.loadQuestion(question.questionId, question.position)}}},[_vm._v(" "+_vm._s(question.position)+" ")])],1)}),1)],1)],1),_c('v-col',{attrs:{"cols":"12","md":"8","order-md":"first"}},[_c('v-sheet',{attrs:{"min-height":"200","rounded":"lg","outlined":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-card-title',[_vm._v(_vm._s(_vm.subtest.testName)+" - "+_vm._s(_vm.subtest.subjectName)+" • "+_vm._s(this.questionPosition))])],1)],1),_c('v-divider'),_c('div',{staticClass:"pa-4"},[_c('span',{domProps:{"innerHTML":_vm._s(_vm.question.question)}}),_c('v-divider',{staticClass:"mt-4"}),_c('v-radio-group',{attrs:{"hide-details":"","readonly":""},model:{value:(_vm.selectedAnswer),callback:function ($$v) {_vm.selectedAnswer=$$v},expression:"selectedAnswer"}},_vm._l((_vm.question.answers),function(answer){return _c('v-radio',{key:answer.id,class:("pa-2 " + (answer.id === _vm.correctAnswer.id ? 'radio' : '')),attrs:{"value":answer.id},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('div',{staticClass:"d-flex align-center",staticStyle:{"width":"100%"}},[_c('div',{class:("" + (answer.id === _vm.correctAnswer.id ? 'white--text' : '')),domProps:{"innerHTML":_vm._s(answer.answer)}})])]},proxy:true}],null,true)})}),1)],1),(_vm.explanation !== null)?_c('div',{staticClass:"pa-4"},[_c('v-divider'),_c('h3',{staticClass:"mt-4"},[_vm._v("Explanation:")]),_c('div',{domProps:{"innerHTML":_vm._s(_vm.explanation)}})],1):_vm._e(),_c('v-divider'),_c('v-card-actions',[(_vm.questionNumber > 1)?_c('v-btn',{attrs:{"color":"primary"},on:{"click":function($event){_vm.loadQuestion(
                _vm.findQuestionIdByPosition(_vm.questionNumber - 1),
                _vm.questionNumber - 1
              )}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-menu-left")]),_vm._v(" Previous Question ")],1):_vm._e(),_c('v-spacer'),(_vm.questionNumber !== _vm.questions.length)?_c('v-btn',{attrs:{"color":_vm.questionNumber === _vm.questions.length ? 'error' : 'primary'},on:{"click":function($event){_vm.questionNumber === _vm.questions.length
                ? _vm.confirmendSubtestDialog()
                : _vm.loadQuestion(
                    _vm.findQuestionIdByPosition(_vm.questionNumber + 1),
                    _vm.questionNumber + 1
                  )}}},[_vm._v(" Next Question "),(_vm.questionNumber !== _vm.questions.length)?_c('v-icon',{attrs:{"right":""}},[_vm._v("mdi-menu-right")]):_vm._e()],1):_vm._e()],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }